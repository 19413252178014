import AcceptCookies from "./Cookies"
import PrivacyPolicy from "./PrivacyPolicy"

const Compliance = () => {
  return (
    <>
      <PrivacyPolicy />
      <AcceptCookies />
    </>
  )
}

export default Compliance
