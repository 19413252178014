import useRedirect from "./useRedirect"
import { logoutUser } from "../services/actions/authentication.actions"

const useLogout = () => {
  const redirect = useRedirect()

  const handleLogout = async () => {
    try {
      const response = await logoutUser()
      if (response) {
        redirect()
      }
    } catch (error) {
      redirect()
    }
  }

  return handleLogout
}

export default useLogout
