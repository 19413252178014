import { createSlice } from "@reduxjs/toolkit"
import { naira } from "../../utils/constants"

const initial = {
  currency: naira,
  products: [],
  investments: [],
  accounts: [],
  activeBanks: [],
  iNestSavings: [],
  pendingInvestments: [],
}

const systemReducer = createSlice({
  name: "systemControllers",
  initialState: { ...initial },
  reducers: {
    switch(state, actions) {
      state.currency = actions.payload
    },
    setProducts(state, actions) {
      state.products = actions.payload
    },
    setInvestments(state, actions) {
      state.investments = actions.payload
    },
    setPendingInvestments(state, actions) {
      state.pendingInvestments = actions.payload
    },
    setInest(state, actions) {
      state.iNestSavings = actions.payload
    },
    setAccounts(state, actions) {
      state.accounts = actions.payload
    },
    setBanks(state, actions) {
      state.activeBanks = actions.payload
    },
    clear() {
      return initial
    },
  },
})

export default systemReducer.reducer
export const systemControllersActions = systemReducer.actions
