export const RouteNav = {
  home: "/",
  login: "/auth/login",
  signup: "/auth/signup",
  resetPassword: "/auth/reset-password",
  selectAccount: "/auth/select-account",
  forgetPassword: "/auth/forgot-password",
  settings: "/settings",
  investments: "/investments",
  savings: "/savings",
  selectTab: (type) => `/${type}`,
  singleInvestment: (type, name) => `/${type}/${name}`,
  investmentSummary: (id) => `/summary/${id}`,
  createInvestment: (type, name) => `/${type}/${name}/create`,
  liquidateInvestment: (id) => `/liquidate/${id}`,
  invoice: "/transaction-invoice",
  faq: "/faqs",
  notFound: "/404",
}
