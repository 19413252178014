import { Box, Drawer } from "@mui/material"
import { useEffect, useState } from "react"
import AppButton from "../common/AppButton"

const key = window.location.origin + "_cookies"

const AcceptCookies = () => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const doesExist = sessionStorage.getItem(key)
    if (!doesExist) {
      setTimeout(() => {
        setIsOpen(true)
      }, 3000)
    }
  }, [])

  const handleAccept = () => {
    sessionStorage.setItem(key, "true")
    setIsOpen(false)
  }

  return (
    <Drawer anchor="bottom" open={isOpen}>
      <Box
        width="auto"
        role="presentation"
        className="p-5 flex flex-col gap-2.5 border-t-4 border-primary"
      >
        <h3 className="text-black font-bold text-lg text-center md:text-left">We Use Cookies!</h3>
        <p className="text-black opacity-90 text-center md:text-left">
          FCMB website uses cookies to ensure necessary functionality, improve your experience and
          analyze our traffic. By continuing into the website, you have given consent to our use of
          cookies. For more information read our{" "}
          <a
            className="text-primary underline hover:opacity-100"
            href="https://www.fcmb.com/privacy-policy"
            rel="noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
        </p>
        <div className="flex flex-wrap gap-2.5 mt-2.5 md:mt-1 self-center justify-center md:self-end">
          <AppButton
            gradient
            onClick={handleAccept}
            className="!min-w-[140px] flex-none h-[45px] opacity-95 hover:opacity-100"
          >
            Accept All
          </AppButton>
          <AppButton
            onClick={() => setIsOpen(false)}
            className="!min-w-[140px] flex-none text-disabled border border-disabled h-[45px] hover:bg-gray-50"
          >
            Reject All
          </AppButton>
        </div>
      </Box>
    </Drawer>
  )
}

export default AcceptCookies
