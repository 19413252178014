import { createSlice } from "@reduxjs/toolkit"
import { decrypt } from "../../utils/security"

const key = process.env.REACT_APP_ENCRYPTION_KEY
const iv = process.env.REACT_APP_ENCRYPTION_IV

let authData = {
  isLoggedIn: false,
}

const storage = localStorage.getItem("GRO")
if (storage) {
  const savedData = JSON.parse(decrypt(JSON.parse(storage), key, iv))
  authData = savedData
}

const userAuthReducer = createSlice({
  name: "userAuth",
  initialState: { ...authData },
  reducers: {
    login(state, action) {
      state.data = { ...action.payload.data }
      state.isLoggedIn = true
    },
    logout(state) {
      state.isLoggedIn = false
      delete state.data
    },
    update(state, action) {
      state.data = { ...state.data, ...action.payload }
    },
  },
})

export default userAuthReducer.reducer
export const userAuthActions = userAuthReducer.actions
