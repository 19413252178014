import ReactGA from "react-ga"
import { useNavigate } from "react-router-dom"

import { IMAGES } from "../../assets"
import AppButton from "../../components/common/AppButton"

ReactGA.pageview(window.location.pathname)

const Error = () => {
  const navigate = useNavigate()
  return (
    <div className="error-container w-screen h-screen p-5 flex flex-col items-center justify-center">
      <div className="error-image md:w-3/5 lg:w-2/5">
        <img alt="Error" src={IMAGES.error} className="w-full" />
      </div>
      <div className="message md:w-3/5 flex flex-col items-center gap-2">
        <p className="w-full text-sm text-center font-medium uppercase">
          the page you are looking for doesn't exist
        </p>
        <p className="w-full text-center">You may have mistyped, or the page has been moved</p>
      </div>
      <AppButton
        className="mt-8 rounded mb-2 !h-[45px] !uppercase !w-[200px]"
        onClick={() => navigate(-1)}
        gradient
      >
        Go back
      </AppButton>
    </div>
  )
}

export default Error
