import { useSelector } from "react-redux"
import { Navigate, Outlet } from "react-router-dom"
import { RouteNav } from "./routeNav"

const Authenticated = () => {
  const isLoggedIn = useSelector((state) => state?.userAuth?.isLoggedIn)
  return isLoggedIn ? <Outlet /> : <Navigate to={RouteNav.login} />
}

export default Authenticated
